import React, {useMemo} from 'react'
import { Helmet } from 'react-helmet'
import { MainLayout } from '../components/layouts/Main'
import { SEO } from '../components/ui-components/Seo'
import { getTextByKey } from '../utils'
import { PriceForm } from '../components/ui-components/forms'

const ContactsTemplate = ({
    pageContext: {
        meta,
        language,
        texts,
    }
}) => {
    const seo = useMemo(() => ({
        title: meta[0].title,
        description: meta[0].description,
        keywords: meta[0].keywords,
        lang: meta[0].lang,
    }), [meta])

    const priceForm = useMemo(() => ({
        header: getTextByKey(texts, 'contacts-form-header'),
        name: getTextByKey(texts, 'contacts-name-placeholder'),
        phone: getTextByKey(texts, 'contacts-phone-placeholder'),
        email: getTextByKey(texts, 'contacts-email-placeholder'),
        button: getTextByKey(texts, 'contacts-button-more'),
        thanks: getTextByKey(texts, 'contacts-button-thanks')
    }), [getTextByKey, texts])

    return (
        <MainLayout
            urlPrefix='contacts'
            language={language}
        >
            <Helmet>
                <script>
                    fbq('track', 'Contact');
                </script>
                {`<!-- Hotjar Tracking Code for Ndesign -->`}
                <script>
                    {`(function(h,o,t,j,a,r){
                        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                        h._hjSettings={hjid:3765080,hjsv:6};
                        a=o.getElementsByTagName('head')[0];
                        r=o.createElement('script');r.async=1;
                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                        a.appendChild(r);
                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
                </script>
            </Helmet>
            <SEO {...seo}/>
            <PriceForm form={priceForm}/>
        </MainLayout>
    )
}

export default ContactsTemplate